import React from "react";
import Zoom from "react-reveal/Zoom";
import PhoneImage from "../../assets/images/5.png";
import "./becomeaprovider.css";
export default function BecomeAprovider() {
  return (
    <section className="become-a-provider-view" id="become-a-provider">
      <div className="container">
        <h1>Become a Provider</h1>
        <div className="how-it-works-details">
          <div className="image ">
            <img src={PhoneImage} alt=" Phone" />
          </div>
          <div className="step_cards">
            <Zoom down>
              <div className="step_card">
                <span className="step_card-count">1</span>
                <p> Download the app from your store. </p>
              </div>
            </Zoom>
            <Zoom down delay={400}>
              <div className="step_card">
                <span className="step_card-count">2</span>
                <p> Sign up as a customer </p>
              </div>
            </Zoom>
            <Zoom down delay={300}>
              <div className="step_card">
                <span className="step_card-count">3</span>
                <p> Click on your needed service </p>
              </div>
            </Zoom>
            <Zoom down delay={200}>
              <div className="step_card">
                <span className="step_card-count">4</span>
                <p> Choose a professional/provider and book service. </p>
              </div>
            </Zoom>
            <Zoom down>
              <div className="step_card">
                <span className="step_card-count">5</span>
                <p> Click on the three dots on the top left of the screen. </p>
              </div>
            </Zoom>
            <Zoom down delay={400}>
              <div className="step_card">
                <span className="step_card-count">6</span>
                <p> Fill out the form </p>
              </div>
            </Zoom>
            <Zoom down delay={300}>
              <div className="step_card">
                <span className="step_card-count">7</span>
                <p> Leave your app as a provider to remain visible</p>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </section>
  );
}
