import React from "react";
import PromoteSection from ".././sections/Promote/Promote";
import EarnSection from ".././sections/Earn/Earn";
import HowItWorkSection from ".././sections/HowItWorks/HowItWorks";
import Banner from ".././components/Banner/Banner";
import UniqueFeatures from "../sections/UniqueFeatures/UniqueFeatures";
import AppInfo from "../sections/AppInfo/AppInfo";
import AdVideo from "../sections/AdVideo/AdVideo";
import JobSlider from "../sections/JobSliders/JobSlider";
import FAQ from "../sections/FAQ/FAQ";
import AppFeatures from "../sections/AppFeatures/AppFeatures";
import BecomeAprovider from "../sections/HowItWorks/BecomeAprovider";

const Home = () => {
  return (
    <div>
      <Banner />
      <PromoteSection />
      <UniqueFeatures />
      <AdVideo />
      <JobSlider />
      <AppInfo />
      <EarnSection />
      <HowItWorkSection />
      <BecomeAprovider />
      <FAQ />
      <AppFeatures />
    </div>
  );
};

export default Home;
