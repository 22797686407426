import Accordion from "../../components/Accordion/Accordion";
import "./faq.css";
const FAQ = () => {
  const faq = [
    {
      title: "What is Ajebuta.com",
      content:
        "Ajebuta.com is a new digital app platform for accessing the services of professionals and skilled personnel engaging in everyday services within our vicinity. ",
    },
    {
      title: "Which services are available on Ajebuta.com ",
      content:
        " - Lifestyle services  <br/> - Technical services <br/> - Construction services <br/> - Health services <br/>  - Legal/Financial/General services <br/>  - A Vendors hub",
    },
    {
      title: "Do I pay to download or use Ajebuta.com App? ",
      content:
        "No. The Ajebuta.com app is completely free to download and it’s services to customers is Free of charge.",
    },
    {
      title: "How do I get started? ",
      content:
        "To get started, Download the app from AppStore or Google playstore to begin and sign up. ",
    },
    {
      title: "Are the providers found with the app certified by AJEBUTA.com? ",
      content:
        "No. Ajebuta.com does not certify any service provider but shall do it best to verify that the services they offer conform with their registration. ",
    },
    {
      title: "How do I become a service provider? ",
      content:
        "To become a service provider download the app, first of all register as a customer, then click the 3 white dots at the top right corner of the home page to reveal the signup box. Then register as a Provider. ",
    },
  ];
  return (
    <section className="faq-section" id="faq">
      <div className="container">
        <h1>Popular Questions and Answers</h1>
        <div className="accordion-group">
          {faq.map((data, index) => (
            <Accordion title={data.title} content={data.content}  key={index}/>
          ))}
        </div>
      </div>
    </section>
  );
};
export default FAQ;
