import React from "react";
import EarnIphone from "../../assets/images/1.png";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import "./Earn.css";
const Earn = () => {
  return (
    <section className="earn-view">
      <div className="container">
        <div className="earn-text">
          <Slide left>
            <div className="heading-rule-under">
              <h1>Become Ajebo!</h1>
            </div>
            <div className="Earn-sub-text">
             
            </div>
            <p>
              {" "}
              <a
                href="https://apps.apple.com/ng/app/ajebuta-com-b2c-allservicesapp/id1550255040"
                style={{
                  textDecoration: "none",
                }}
                className="btn btn-primary subscribe-btn"
              >
                Sign Up
              </a>{" "}
            </p>
          </Slide>
        </div>
        <Zoom out>
          <div className="earn-image ">
            <img src={EarnIphone} alt="Iphone" />
          </div>
        </Zoom>
      </div>
    </section>
  );
};
export default Earn;
