import React, {useState, useRef} from "react";
import ChevronRight from "./ChevronRight.js";
import "./Accordion.css";
const Accordion = (props) => {
	const [setActive, setActiveState] = useState("");
const [setHeight, setHeightState] =useState("0px");
const [setRotate, setRotateState] =useState("accordion-icon");
const content = useRef(null)
	function toggleAccordion() {
		setActiveState(setActive===""?"active":"");
		setHeightState(setActive==="active"?"0px": `${content.current.scrollHeight}px`);
		setRotateState(
      setActive === "active" ? "accordion-icon" : "accordion-icon rotate"
    );

	}
	return (
			<div className="accordion">
			<button className={`accordion_ ${setActive}`} onClick={toggleAccordion}>
			<p className="accordion-title">{props.title}</p>
			<ChevronRight className={`${setRotate}`} width={10} fill={"#d33800"}/>
			</button>
			<div ref={content}
			style={{maxHeight:`${setHeight}`}}
			className="accordion-content">
			<div className="accordion-text" 
			dangerouslySetInnerHTML ={{__html:props.content}}
			>
			</div>
			</div>
			

			</div>
		)
}
export default Accordion;
