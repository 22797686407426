import React from "react";
import Home from "././pages/Home.js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "././components/Footer/Footer.js";
function App() {
  return (
    <Router>
      <main>
        <Switch>
          <Route exact={true} path="/">
            <Home />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
