import React, { useState } from "react";
import PropTypes, { number } from "prop-types";
import Truncate from "react-truncate";
const TruncatedRead = ({ children, less, lines, more, width,ref }) => {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const handleTruncate = () => {
    if (truncated !== truncated) {
      setTruncated(truncated);
    }
  };
  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };
  return (
    <div>
      <Truncate
        lines={!expanded && lines}
        width={width}
        trimWhitespace={true} 
        ellipsis={
          <span>
            ...{" "}
            <a
              href="#"
              style={{
                textDecoration: "none",
                color: "#d3833d",
              }}
              onClick={toggleLines}
            >
              {more}
            </a>
          </span>
        }
        onTruncate={handleTruncate}
      >
        {children}
      </Truncate>
      {!truncated && expanded && (
        <span>
          <a
            href="#"
            className=""
            style={{
              textDecoration: "none",
              color: "#d3833d",
            }}
            onClick={toggleLines}
          >
            {less}
          </a>
        </span>
      )}
    </div>
  );
};

TruncatedRead.defaultProps = {
  lines: 3,
  more: "read more",
  less: "show less",
};

TruncatedRead.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  width: PropTypes.string || PropTypes.number,
  
};

export default TruncatedRead;
