import React from "react";
import "./HowItWorks.css";
import Zoom from "react-reveal/Zoom";
import PhoneImage from "../../assets/images/5.png";
const HowItWorks = () => {
  return (
    <section className="how-it-works-view" id="howitworks">
      <div className="container">
        <h1>Enjoy Our Services</h1>
        <div className="how-it-works-details">
          <div className="image ">
            <img src={PhoneImage} alt=" Phone" />
          </div>
          <div className="step_cards">
         <Zoom down>
          <div className="step_card">
            <span className="step_card-count">1</span>
          <p>  Download the app from your store. </p>
          </div>
          </Zoom>
          <Zoom down delay={400}>
          <div className="step_card">
            <span className="step_card-count">2</span>
          <p>  Sign up as a customer </p>
          </div>
          </Zoom>
          <Zoom down delay={300}>
          <div className="step_card">
            <span className="step_card-count">3</span>
          <p>  Click on your needed service </p>
          </div>
          </Zoom>
          <Zoom down delay={200}>
          <div className="step_card">
            <span className="step_card-count">4</span>
          <p>  Choose a professional/provider and book service. </p>
          </div>
          </Zoom>
          

          </div>

        </div>
      </div>


      
    </section>
  );
};
export default HowItWorks;
