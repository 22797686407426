import "./Navbar.css";
import AjebutaLogo from "../../assets/images/logo.png";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
const NavUnlisted = styled.ul`
  a {
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    display: block;
    margin-bottom: 2rem;
    transition: all 0.6s;
  }
  a:hover {
    color: #ea8000;
  }
  .current {
    color: #ea8000;
  }
`;
const Navbar = () => {
  const [click, setClick] = React.useState(false);
  const [nav, setNav] = React.useState(false);

  const handleClick = () => {
    if (!click) {
      setClick(!click);
    } else {
      if (click) {
        setClick(false);
      }
    }
  };
  const handleScroll = () => {
    if (window.pageYOffset > 140) {
      if (!nav) {
        setNav(true);
      }
    } else {
      if (nav) {
        setNav(false);
      }
    }
  };

  const closeNav = () => {
    if (click) {
      setClick(false);
    }
  };
  window.addEventListener("scroll", handleScroll);
  React.useEffect(handleScroll, []);
  return (
    <div className={nav ? "nav-wrapper sticky" : "nav-wrapper"}>
      <nav className="nav container">
        <div className="nav-brand">
          <Link to="/">
            <img src={AjebutaLogo} alt="Druve Logo" />{" "}
          </Link>
        </div>
        <NavUnlisted
          className={click ? "nav-menu-group mobile" : "nav-menu-group"}
        >
          <li className="nav-menu-list">
            <NavLink onClick={closeNav} exact to="/" activeClassName="current">
              Home
            </NavLink>
          </li>
          <li className="nav-menu-list">
            <a onClick={closeNav} href="#ufeatures">
              Features
            </a>
          </li>
          <li className="nav-menu-list">
            <a onClick={closeNav} href="#services">
              Services
            </a>
          </li>
          <li className="nav-menu-list">
            <a onClick={closeNav} href="#howitworks">
              How it works
            </a>
          </li>
          <li className="nav-menu-list">
            <a href="#faq" onClick={closeNav}>
              FAQ
            </a>
          </li>
          <li className="nav-menu-list">
            <a onClick={closeNav} href="#contact">
              Contact Us
            </a>
          </li>

          <li className="nav-menu-list">
            <a
              className="btn btn-primary"
              href="https://apps.apple.com/ng/app/ajebuta-com-b2c-allservicesapp/id1550255040"
              target="_blank"
              rel="noreferrer"
            >
              Download App
            </a>
          </li>
        </NavUnlisted>
        {/* MOBILE MENU*/}
        <div className={click ? "bars clicked" : "bars "} onClick={handleClick}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
