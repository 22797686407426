import Slider from "react-slick";
import { FaDownload } from "react-icons/fa";
import "./slider.css";
const JobSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const slidesData = [
  //   {
  //     title: "Do you need an A.C Repairer?",
  //     img: require("../../assets/images/airconditioning-repair-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Technical Service Provider?",
  //     img: require("../../assets/images/Electrician-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Mason Bricklayer?",
  //     img: require("../../assets/images/Mason-Brick-layer-labourer-2.jpg").default,
  //   },
  //   {
  //     title: "Do you need an Health Service Provider?",

  //     img: require("../../assets/images/Nurse-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Barrister or Solicitors?",
  //     img: require("../../assets/images/law.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Web Developer or Web Designer?",
  //     img: require("../../assets/images/Website-Design-2.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Towing Service Provider?",
  //     img: require("../../assets/images/Tow-Service-4.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Transport Haulage?",
  //     img: require("../../assets/images/Transport-Haulage-2.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Vehicular Haulage",
  //     img: require("../../assets/images/Vehicular-Haulage-.jpg").default,
  //   },
  //   {
  //     title: "Do you need an Veterinary Doctor?",
  //     img: require("../../assets/images/Vet-Doctor-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Vulcanizer",
  //     img: require("../../assets/images/Vulcanizer-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Water Drilling Service?",
  //     img: require("../../assets/images/Water-Drilling-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Welder?",
  //     img: require("../../assets/images/Welder-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need an Architect?",
  //     img: require("../../assets/images/Architect.jpg").default,
  //   },
  //   {
  //     title: "Do you need an Artist?",
  //     img: require("../../assets/images/Artists.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Barber?",
  //     img: require("../../assets/images/Barber 3 .jpg").default,
  //   },
  //   {
  //     title: "Do you need a Bicycle Repairer?",
  //     img: require("../../assets/images/Bicycle-Repair-3.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Building Engineer?",
  //     img: require("../../assets/images/BIUlding-Engineer.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Car A.C Gas Refiller?",
  //     img: require("../../assets/images/Car-AC-Gas-Refill-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Car Mechanic?",
  //     img: require("../../assets/images/Car-Mechanic-2-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Car Wash Service Provider",
  //     img: require("../../assets/images/Car-Wash.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Carpenter?",
  //     img: require("../../assets/images/Carpenter-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Chef?",
  //     img: require("../../assets/images/Chef-1.jpg").default,
  //   },
  //   {
  //     title: " Do you need an Househelp?",
  //     img: require("../../assets/images/Cleaner-and-Househelp.jpg").default,
  //   },
  //   {
  //     title: " Do you need a Cleaner?",
  //     img: require("../../assets/images/CLeaner-Office.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Computer Repairer?",
  //     img: require("../../assets/images/Computer-Repair-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Dentist?",
  //     img: require("../../assets/images/Dentists.jpg").default,
  //   },  {
  //     title: "Do you need an Optician?",
  //     img: require("../../assets/images/Eye-Doctor-Optician-2.jpg").default,
  //   },  {
  //     title: "Do you need a Opthamologist?",
  //     img: require("../../assets/images/Eye-Doctor-Optamologist-2.jpg").default,
  //   },  {
  //     title: "Do you need a Plumber?",
  //     img: require("../../assets/images/Plumbing-1.jpg").default,
  //   }, {
  //     title: "Do you need a Panel Beater?",
  //     img: require("../../assets/images/Panel-Beater-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Fitness Instructor?",
  //     img: require("../../assets/images/Fitness-Instructor-2.jpg").default,
  //   },
  //   {
  //     title: "Do you need an Hair Stylist?",
  //     img: require("../../assets/images/Hair-Stylist-2.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Fashion Designer?",
  //     img: require("../../assets/images/Fashion-Designer-2.jpg").default,
  //   }, {
  //     title: "Do you need a Spa?",
  //     img: require("../../assets/images/Spa-2.jpg").default,
  //   },
  //    {
  //     title: "Do you need a Cinematographer?",
  //     img: require("../../assets/images/Photographers-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Barber?",
  //     img: require("../../assets/images/Barber 3 .jpg").default,
  //   },
  //   {
  //     title: "Do you need a Makeup Artist?",
  //     img: require("../../assets/images/Make-up-Artist-1.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Photographer?",
  //     img: require("../../assets/images/Photographers-1.jpg").default,
  //   },{
  //     title: "Do you need a Disk Jockey (DJ)?",
  //     img: require("../../assets/images/DJ-.jpg").default,
  //   },
  //   {
  //     title: "Do you need a Driver?",
  //     img: require("../../assets/images/driver.jpg").default,
  //   }, {
  //     title: "Do you need a Surveyor?",
  //     img: require("../../assets/images/Surveyor-1-.jpg").default,
  //   },

  // ];

  const imagesAd = [
    {
      image: require("../../assets/images/Ajebuta.com website designs. 1.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 2-c.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 3.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 4.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 5.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 13.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 7.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 8.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 9.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 10.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 11.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 12.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 14.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 15.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 16.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 17.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 18.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 19.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 21.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 22.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 23.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 24.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 25.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 26.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 27.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 28.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 29.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 30.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 31.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 32.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 33.png")
        .default,
    },
    {
      image: require("../../assets/images/Ajebuta.com website designs. 34.png")
        .default,
    },
  ];
  return (
    <section className="card-slider" id="services">
      <div className="container">
        <div className="card-slider-heading">
          <h1>Featured Services</h1>
          <p>List of feature services</p>
        </div>
        <Slider {...settings}>
          {imagesAd.map((data, index) => (
            <div key={index} className="service-card">
              <div className="service-card-image">
                <img src={data.image} alt="" />
              </div>

              <div className="service-card-content">
                {/* <p className="service-card-title">{data.title}</p> */}
                <a
                  href="https://apps.apple.com/ng/app/ajebuta-com-b2c-allservicesapp/id1550255040"
                  className="btn btn-primary"
                >
                  <span>Download</span>
                  <span>
                    <FaDownload size="30" />
                  </span>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
export default JobSlider;
