import "./appinfo.css";
import {
  FaBath,
  FaWrench,
  FaBuilding,
  FaAmbulance,
  FaUniversity,
  FaGavel,
} from "react-icons/fa";
import Slide from "react-reveal/Slide";
const AppInfo = () => {
  return (
    <section className="feature-section">
      <div className="container">
        <div className="feature-section-heading">
          {" "}
          <h1> Sign Up Now! </h1>
          <p>All Professionals and Service Providers Now At Your Finger Tips</p>
        </div>

        <div className=" features-list">
          <Slide up>
            <div className="group-one">
              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaBath size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Lifestyle Providers</p>
                  </div>
                </Slide>
              </div>

              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaWrench size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Technical Service Providers</p>
                  </div>
                </Slide>
              </div>

              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaBuilding size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Construction Service Providers</p>
                  </div>
                </Slide>
              </div>
            </div>
          </Slide>
          <Slide up>
            <div className="group-two">
              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaAmbulance size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Specialist & Professionals</p>
                  </div>
                </Slide>
              </div>

              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaUniversity size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Legal/Financial/General services</p>
                  </div>
                </Slide>
              </div>

              <div className="card">
                <p className="card-icons">
                  {" "}
                  <FaGavel size="20" />
                </p>
                <Slide right delay={200}>
                  <div className="card-title">
                    <p>Vendors Hub</p>
                  </div>
                </Slide>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </section>
  );
};
export default AppInfo;
