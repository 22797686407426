import React, { useState } from "react";
import PromoteIphone from "../../assets/images/1.png";
import "./Promote.css";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import TruncatedRead from "../../components/TruncatedRead/TruncatedRead";
const Promote = () => {
  return (
    <section className="promote-view">
      <div className="container">
        <div className="promote-text">
          <Slide right>
            <h1>AJEBUTA.com</h1>

            <TruncatedRead ellipsis={"... Read more"} lines={4}>
              <p className="promote-sub-text">
                The Ajebuta.com app is the latest easy-to-use digital platform
                that helps to locate and connect customers to nearby service
                providers, professionals and online vendors from the convenience
                of a mobile device.
              </p>

              <h3> Get Connected to verified professionals</h3>
              <p className="promote-sub-text">
                The Ajebuta.com app gives u access to professionals and service
                providers near you that have been verified. Finally a safe and
                secure way to acquire services without fear.
              </p>

              <h3> Never Get service-stranded Again</h3>
              <p className="promote-sub-text">
                Sometimes we may need medical help or be stranded on the road or
                need to fix a gadget or maintain our house, now we can get help
                and never be stranded again with the Ajebuta.comapp.
              </p>

              <h3>Skilled workers in a few clicks</h3>
              <p className="promote-sub-text">
                We often have a variety of needs daily where we need skilled
                personnel. Now, Ajebuta.com has categories on Technical services
                providers and Construction services providers that will help you
                take care of your needs quickly.
              </p>
              <h3>Financial and Legal Professionals Access</h3>
              <p className="promote-sub-text">
                We can now have access to people in the financial and legal
                professions without using a search engine. Verified
                Professionals at your finger tips.
              </p>
              <h3>No More Medical emergencies</h3>
              <p className="promote-sub-text">
                One of the biggest emergencies we can have may be medical, this
                app links you to the nearest doctors and ambulance services
                closest to you in a timely manner.
              </p>
              <h3>Agents of all services available here</h3>
              <p className="promote-sub-text">
                When we need help for some vital services many agents
              </p>
              <p>
                <a href="https://apps.apple.com/ng/app/ajebuta-com-b2c-allservicesapp/id1550255040">
                  <button className="btn btn-primary subscribe-btn">
                    Get Started
                  </button>
                </a>
              </p>
            </TruncatedRead>
          </Slide>
        </div>

        <Zoom out>
          <div className="promote-image">
            <img src={PromoteIphone} alt="Iphone " />
          </div>{" "}
        </Zoom>
      </div>
    </section>
  );
};
export default Promote;
