
import ReactPlayer from "react-player";
import { FaPlayCircle } from "react-icons/fa";
import "./advideo.css";
const AdVideo =  () =>{
    return (
        <section className="ad-video">
            <div className="container">
                <ReactPlayer url="https://www.youtube.com/watch?v=PHlxvHlmCP8" loop={false} controls={true} playing={true} light={true} volume={0.5} playIcon={<FaPlayCircle size="60"/>} className="react-player" width="100%" />
            </div>
        </section>
    )
}

export default AdVideo;