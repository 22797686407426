import React from "react";
import BannerImage from "../../assets/images/3.png";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import "./Banner.css";
import Navbar from "../Navbar/Navbar";

const Banner = () => {
  return (
    <React.Fragment>
      <header>
         <Navbar />
         <br/>
        <div className="banner">
       
          <Fade left>
            <section className="header-main-text">
              <div className="header-texts">              
              {/* <h1><span className="heading-seperator">Ajebuta.com </span>
              <br/>  All Services Locator.</h1> */}
              <img src={require("../../assets/images/logo.png").default}/>
              </div>
              <p className="icon-google-play">
              <a href='https://play.google.com/store/apps/details?id=com.ajebuta.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src={require("../../assets/images/playstoreicon.png").default}/></a>
              <a href="https://apps.apple.com/ng/app/ajebuta-com-b2c-allservicesapp/id1550255040">  
              <img alt="Get it on AppStore" src={require("../../assets/images/512x512.png").default}/>
              </a>
              </p>
            </section>
          </Fade>
          <Zoom up>
            <section className="banner-image">
              <img src={BannerImage} alt="banner_pic" />
            </section>
          </Zoom>
        </div>
      </header>
    </React.Fragment>
  );
};
export default Banner;
