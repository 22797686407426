import React from "react";
import Slide from "react-reveal/Slide";
import TruncatedRead from "../../components/TruncatedRead/TruncatedRead";
import "./appfeatures.css";

const AppFeatures = () => {
  return (
    <section className="app-feature-section">
      <div className="container">
        <div className="app-feature-heading">
          <h1> App Features</h1>
          <p>The following features in our App</p>
        </div>
        <div className="feature-wrapper">
          <Slide up>
            <div className="group-one">
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Professional and elegant UI/UX that would be easy to use
                      by all the users across the spectrum.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Seamless navigation to find the right listings in the
                      least amount of time.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Detect user location at both the ends of the spectrum for
                      mapping the need with the availability. Visibility is
                      restricted to approximately 3 km.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Notifications & reminders for users and service providers.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      The ability to cancel requests by the users or
                      accept/reject service requests by the service providers.
                    </p>
                  </div>
                
              </div>
              </Slide>{" "}
                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      The ability to maintain credits by adding cash and the
                      ability to add cash-credit when running out of credit.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>Different access permissions according to user roles.</p>
                  </div>
                
              </div>
              </Slide>
            </div>
          
          <Slide up>
            <div className="group-two">
                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>Hybrid and web interfaces for users and admins.</p>
                  </div>
                
              </div>
              </Slide>

                <Slide up >
              <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      User registrations for multiple roles such as users, service providers (individuals and
                      businesses) and the admin.
                    </p>
                  </div>
                
              </div>
              </Slide>

                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Dedicated dashboards for all the users to view order and
                      service history. Ability to book appointments using a
                      digital calendar inbuilt within the app.{" "}
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Users should be able to book appointments for immediate
                      delivery, within thirty minutes, within one hour, within
                      two hours, the next day, the next week and other
                      durations.
                    </p>
                  </div>
                
              </div>
              </Slide>

                <Slide up >
      <div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Listing search with multiple criteria such as name,
                      service category, service name and previous service
                      history.
                    </p>
                  </div>
                
              </div>
              </Slide>
                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>The ability to leave reviews & ratings.</p>
                  </div>
                
              </div>
              </Slide>

                <Slide up >
<div className="feature-card">
                  <div className="feature-card-title">
                    <p>
                      Password retrieval in case the existing password is lost.
                    </p>
                  </div>
              </div>
              </Slide>
            </div>
          </Slide>
          </Slide>
          </div>
          </div>
            
        </section>
  );
};
export default AppFeatures;
