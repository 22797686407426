import Slider from "react-slick";
import "./UniqueFeatures.css";
const UniqueFeatures = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cardDetails = [
    {
      title: "No More Medical emergencies",
      icon: require("../../assets/images/Eye-Doctor-Optamologist-2.jpg")
        .default,
      content:
        "One of the biggest emergencies we can have may be  medical, this app links you to the nearest doctors and ambulance services closest to you in a timely manner.",
    },
    {
      title: "Agents of all services available here",
      icon: require("../../assets/images/satelite-dish-installer.jpg").default,
      content:
        "When we need help for some vital services many agents including travel agents, estate agents, clearing agents can now be within reach.",
    },
    {
      title: "A Hub for Product Vendors",
      icon: require("../../assets/images/productv.jpg").default,
      content:
        "Vending and merchandising of products is all around us. This is a great platform to let your services be found by the teaming crowd, simply by registering as a provider. Join the Ajebuta.com family and stay safe during the pandemic.",
    },
    {
      title: "Financial and Legal Professionals Access",
      icon: require("../../assets/images/law.jpg").default,
      content:
        "We can now have access to people in the financial and legal professions without using a search engine. Verified Professionals at your finger tips. ",
    },
    {
      title: "Location Services",
      icon: require("../../assets/images/locationservice.png").default,
      content:
        "Take advantage of our app to locate services within your vicinity very easily. Your personal assistant on the go. ",
    },
  ];

  return (
    <section className="unique__feature" id="ufeatures">
      <div className="container">
        <div className="row">
          <div className="">
            <div className="unique__feature-heading">
              <h1>Unique Features</h1>
            </div>
          </div>
        </div>
        <div className="unique__feature-cards">
          <Slider {...settings}>
            {cardDetails.map((data, index) => (
              <div className="unique__feature-cards__card" key={index}>
                <img src={data.icon} alt="features" />
                <div className="unique__feature-card-content">
                  <h3>{data.title}</h3>

                  <p>{data.content}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default UniqueFeatures;
