import React from "react";
import FacebookIcon from "./facebook.svg";
import TwitterIcon from "./twitter.svg";
import IGicon from "./ig.svg";
import LinkedInIcon from "./linkedin.svg";
import {FaMailBulk, FaPhone,FaSearchLocation} from "react-icons/fa"
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer-content" id="contact">
      <div className=" container">
        <h1> contacts</h1>
        <ul>
          <li>
            <span> ​<FaPhone size="14" /> </span> <p> +234 907 200 0001</p></li>
          <li> <span><FaMailBulk size="14"/></span>  <p> 
          ajebutang@gmail.com</p></li>
          <li> <span><FaSearchLocation size="14"/></span>  <p>  37, Third Avenue, Gwarimpa, FCT Abuja </p></li>
          
        </ul>
      </div>
     
      <p className="footer-label">
        © 2020 | All rights reserved | Ajebuta
      </p>
      <p className="footer__icons">
        <img alt="social media icon" src={FacebookIcon} />
        <img alt="social media icon" src={TwitterIcon} />
        <img alt="social media icon" src={IGicon} />
        <img alt="social media icon" src={LinkedInIcon} />
      </p>
    </div>
  );
};
export default Footer;
